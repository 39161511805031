import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const About = () => {
  return (
    <Layout compact>
      <SEO title="foriio Jobsについて" />
      <h1 className="display-1">foriio Jobsについて</h1>
      <p className="lead text-muted">
        こんにちは、foriioカスタマーチームです。個人の実績をまとめられるポートフォリオサービスから始まったforiioは、クリエイティブ業界でのキャリアのスタートや成長を支援するためのサイトへと発展してきました。
      </p>
      <p className="lead text-muted">
        2019年1月から、クリエイターのポートフォリオプラットフォームとしてスタートしました。個人が自分たちで活用できるツールとして提供されたツールが、今や15,000人以上使われるプラットフォームになりました。その過程で徐々に仕事を探している人達と、クリエイターを探している人達の架け橋が求められている事に気づきました。
      </p>
      <p className="lead text-muted">
        ここにforiio
        jobsという求人情報をスタートしました。クリエイターの方々に新しい機会を提供できるきっかけとなれば幸いです。
      </p>
    </Layout>
  );
};

export default About;
